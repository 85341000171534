import axios from '@/plugins/axios'
import { defineStore } from 'pinia'
import { useDefaultStore } from '@/store/default'
import router from '@/router'
import { swal } from '@/utils/sweetalert'
import { t } from '@/plugins/i18n'
import Posthog from 'posthog-js'
import * as Sentry from '@sentry/vue'

export class User implements Domain.Users.DTO.UserData {
  id: number | null
  email: string | null
  username: string | null
  firstname: string
  lastname: string
  name: string
  is_login_active: boolean
  holidays_per_year: number | null
  is_password_changed: boolean | null
  is_active: boolean
  meilisearch_token: string | null
  customer: Domain.Users.DTO.CustomerData | null
  type: Domain.Users.DTO.UserData['type']
  role: Domain.Users.DTO.RoleData | null
  shortcuts: Domain.Settings.DTO.ShortcutData[] | null
  reservations: Domain.Roomdispositioner.DTO.ReservationData[] | null
  managed_customers: { id: number; name: string }[] | null

  constructor(user: Domain.Users.DTO.UserData) {
    this.id = user.id
    this.email = user.email
    this.username = user.username
    this.firstname = user.firstname || ''
    this.lastname = user.lastname
    this.name = user.name
    this.is_login_active = user.is_login_active
    this.holidays_per_year = user.holidays_per_year
    this.is_password_changed = user.is_password_changed
    this.is_active = user.is_active
    this.meilisearch_token = user.meilisearch_token
    this.customer = user.customer
    this.type = user.type
    this.role = user.role
    this.shortcuts = user.shortcuts
    this.reservations = user.reservations
    this.managed_customers = user.managed_customers
  }

  hasPermission(types: string | string[], roles?: string[]) {
    if (!Array.isArray(types)) types = [types]

    if (types.includes(this.type)) return true
    if (!roles || !this.role) return false

    return !!this.role.authorization_rules.find((r) => roles.includes(r.name))
  }
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as User | null,
  }),

  getters: {
    managedCustomers() {
      return (this.user?.managed_customers || []) as {
        id: number
        name: string
      }[]
    },
    managedCustomerIds() {
      return (this.user?.managed_customers?.map((c) => c.id) || []) as number[]
    },
    selectableCustomerIds() {
      return this.managedCustomerIds.concat(this.user?.customer?.id || [])
    },
  },

  actions: {
    async fetchUser() {
      const user = (await axios.$get('/auth/user').catch((e) => {
        if (!e.response) return

        if (e.response.status === 401) return
        if (e.response.status === 403) return

        swal(t('general.error'), t('general.unknown-error'), 'error')
      })) as User | null

      if (!user) return

      this.user = new User(user)

      if (Posthog.get_distinct_id() !== user.id?.toString()) {
        Posthog.identify(user.id?.toString(), {
          email: user.email,
          name: user.name,
        })
      }

      Sentry.setUser({
        id: user.id?.toString(),
        email: user.email || '',
        name: user.name,
      })

      useDefaultStore().meilisearchApiKey = user.meilisearch_token
    },

    async login({ email, password }) {
      await axios.$post('auth/login', {
        email,
        password,
      })

      await this.fetchUser()

      let redirect = router.currentRoute.value.query.redirect || '/'
      if (redirect === '/login') {
        redirect = '/'
      }
      if (Array.isArray(redirect)) {
        router.push('/')
        return
      }
      router.push(redirect)
    },

    async logout() {
      await axios.$post('/auth/logout')

      router.push({ name: 'auth.login' })

      setTimeout(() => {
        this.user = null
        Posthog.reset()

        Sentry.setUser(null)
      }, 200)
    },
  },
})

import { resourceService } from '@/features/resource-planner/api/ResourceService'
import { confirmAction } from '@/utils'
import { t } from '@/plugins/i18n'
import { defineStore } from 'pinia'
import { useDefaultStore, useEmployeeStore, useToolStore } from '../../../store'
import moment from 'moment'
import { useAuthStore } from '../../../store/auth'
import { useRoute, useRouter } from 'vue-router'
import { errorNotification } from '@/utils/notifications'
import axios from '@/plugins/axios'
import type { SweetAlertOptions } from 'sweetalert2'
import { handleError } from '@/utils/error-handling'
import { plannerEntryService } from '@/features/resource-planner/api/PlannerEntryService'
import debounce from 'lodash/debounce'
import { useSyncRouteParam } from '@/composables/useRouteParams'
import { createDatesArray, type Dateable } from '@/utils/dates'
import { useBreakPoints } from '@/composables/useBreakPoints'
import { useLocalStorage } from '@/composables/useLocalStorage'
import { sheduleQueryUpdate } from '@/utils/router-helper'

export function getCurrentDate() {
  const route = useRoute()
  if (route.query.date) {
    return moment(route.query.date as string).format('YYYY-MM-DD')
  }

  const checkTime = moment().hours(17).minutes(0).seconds(0)

  if (moment().isAfter(checkTime)) {
    return moment().add(1, 'days').format('YYYY-MM-DD')
  }
  return moment().format('YYYY-MM-DD')
}

export type ResourceCollectionByDay = {
  date: string
  resources: Domain.ResourcePlanner.DTO.ResourceData[]
  items: ResourceCollection[]
}

export type ResourceCollection = {
  id: number | string
  resources: Domain.ResourcePlanner.DTO.ResourceData[]
  name: string
  planner_entries: Domain.ResourcePlanner.DTO.PlannerEntryData[]
  customer: Domain.Users.DTO.CustomerData | null
}

export const useResourcePlannerStore = defineStore('resourcePlanner', () => {
  // state
  const breakpoints = useBreakPoints()

  const loadingDates = ref<string[]>([])
  const loadingResourceIds = ref<number[]>([])

  const date = ref(getCurrentDate())
  const router = useRouter()
  watch(date, () => {
    sheduleQueryUpdate({ date: date.value }, router)
  })

  const additionalDays = useLocalStorage('resourcePlanner.additionalDays', {
    before: 0,
    after: 0,
  })

  const searchString = ref<string | null>(null)
  const dateRange = computed<[moment.Moment, moment.Moment]>(() => {
    const firstDate = moment(date.value).subtract(
      breakpoints.lg.value ? additionalDays.value.before || 0 : 0,
      'days'
    )
    const lastDate = moment(date.value).add(
      breakpoints.lg.value ? additionalDays.value.after || 0 : 0,
      'days'
    )

    return [firstDate, lastDate]
  })

  const resourcesSearchResult = ref<Domain.ResourcePlanner.DTO.ResourceData[]>(
    []
  )

  const resourceListCache = new Map<
    string,
    Domain.ResourcePlanner.DTO.ResourceData[]
  >()
  const resourceCache = new Map<
    number,
    Domain.ResourcePlanner.DTO.ResourceData
  >()
  function clearResourceCache(resourceId: number | null = null) {
    if (resourceId) {
      resourceCache.delete(resourceId)
    } else {
      resourceCache.clear()
    }
  }

  // cache resources response by date
  const currentResources = ref(
    new Map<
      string, // date
      Domain.ResourcePlanner.DTO.ResourceData[]
    >()
  )
  watch(
    currentResources,
    () => {
      currentResources.value.forEach((resources, date) => {
        resourceListCache.set(date, resources)
      })
    },
    {
      deep: true,
    }
  )

  watch(
    searchString,
    debounce(async () => {
      const { data } = await resourceService.getResources({
        'date:between': dateRange.value.map((d) => d.format('YYYY-MM-DD')),
        search: searchString.value,
        perPage: -1,
      })
      resourcesSearchResult.value = data
    }, 300)
  )

  const loadingResources = ref(false)
  const openResourceCollection = ref<ResourceCollection | null>(null)
  const selectedResourceId = useSyncRouteParam<number | null>('resourceId', {
    defaultValue: null,
    type: 'number',
  })
  const getResourcesController = ref<AbortController | null>(null)

  watch(openResourceCollection, (collection) => {
    if (!collection) return
    const selectedCustomerResource = allResourceCollections.value.find(
      (g) => g.id === collection.id
    )
    if (!selectedCustomerResource) return

    if (selectedCustomerResource.resources.length === 1) {
      selectedResourceId.value = selectedCustomerResource?.resources[0]?.id
      return
    }
    if (
      selectedCustomerResource.resources.some(
        (r) => r.id === selectedResourceId.value
      )
    ) {
      return
    }
  })

  // getters
  const resourceCollectionsByDay = computed<ResourceCollectionByDay[]>(() => {
    const [firstDate, lastDate] = dateRange.value

    const collections: ResourceCollectionByDay[] = []

    for (
      const date = firstDate.clone();
      date <= lastDate;
      date.add(1, 'days')
    ) {
      const resources = (
        currentResources.value.get(date.format('YYYY-MM-DD')) || []
      ).filter((r) => {
        if (!searchString.value) {
          return true
        }

        return resourcesSearchResult.value.find((res) => res.id === r.id)
      })
      collections.push({
        date: date.format('YYYY-MM-DD'),
        resources: resources,
        items: resourcesToCollections(resources),
      })
    }

    return collections
  })

  const allResources = computed(() => {
    return resourceCollectionsByDay.value.flatMap((r) => r.resources)
  })
  const selectedResource = computed(() =>
    allResources.value.find((r) => r.id === selectedResourceId.value)
  )

  const resourcesAtSelectedDay = computed(
    () => currentResources.value.get(date.value) || []
  )

  const selectedEmployeeIds = ref<number[]>([])
  function calculateSelectedEmployeeIds() {
    selectedEmployeeIds.value = resourcesAtSelectedDay.value
      .flatMap((r) => r.planner_entries!)
      .map((r) => r.employee!.id)
  }

  const usedCarIds = computed(() =>
    resourcesAtSelectedDay.value.flatMap((r) => r.cars!).map((c) => c.id)
  )
  function getAmountOfUsePerTool(date: Dateable) {
    return getTools(date).reduce((prev, curr) => {
      prev[curr.id] = prev[curr.id]
        ? prev[curr.id] + (curr.pivot?.amount ?? 0)
        : curr.pivot?.amount ?? 0
      return prev
    }, {})
  }
  const amountOfUsePerTool = computed(() => getAmountOfUsePerTool(date.value))
  const availableTools = computed(() =>
    useToolStore().tools.filter(
      (t) => (amountOfUsePerTool.value[t.id] || 0) < t.amount
    )
  )
  const isAllowedToEdit = computed(
    () =>
      useAuthStore().user?.hasPermission(
        ['superadmin'],
        ['resource_planner_write']
      ) || false
  )
  const disabled = computed(() => !isAllowedToEdit.value)
  const usedCustomers = computed(() =>
    resourcesAtSelectedDay.value
      .filter((r) => r.customer)
      .map((r) => r.customer)
  )

  const allResourceCollections = computed(() => {
    return resourceCollectionsByDay.value.flatMap((r) => r.items)
  })

  function resourcesToCollections(
    resources: Domain.ResourcePlanner.DTO.ResourceData[]
  ): ResourceCollection[] {
    const groups = resources.reduce((groups, resource) => {
      const mapKey = `${resource.worktype_id}-${resource.customer_id}`
      if (groups[mapKey]) {
        groups[mapKey].resources.push(resource)
        groups[mapKey].planner_entries.push(...resource.planner_entries!)
      } else {
        groups[mapKey] = {
          id: resource.id,
          name: resource.customer
            ? resource.customer.name
            : resource.worktype?.name_de,
          resources: [resource],
          planner_entries: [...resource.planner_entries!],
          customer: resource.customer,
        }
      }

      return groups
    }, {})

    return Object.keys(groups).map((key) => groups[key])
  }

  // actions
  function isEditable(
    resource?: Domain.ResourcePlanner.DTO.ResourceData | null
  ) {
    if (!isAllowedToEdit.value) {
      errorNotification(
        'Du hast nicht die nötige Berechtigung um den Ressourcenplaner zu bearbeiten'
      )
      return false
    }

    if (resource && resource.completed) {
      errorNotification(
        'Diese Gruppe wurde bereits abgeschlossen und kann nicht mehr bearbeitet werden'
      )
      return false
    }

    return true
  }

  function canAddItemToResource(_to, from, resource) {
    // tools and cars can not be added to the absance resources (free, sick, accident usw...)
    if (
      (!resource.customer ||
        resource.customer.always_show_on_resource_planner) &&
      ['car', 'tool'].includes(from.el.dataset.draggableName)
    ) {
      return false
    }
    return Number(from.el.dataset.resourceId) !== resource.id
  }

  /**
   * Fetches the details of a single resource and updates the resource array.
   * Used to fetch the details of a resource when opening it or after editing it.
   */
  async function fetchResource(resourceId: number, force = false) {
    if (!force) {
      const resource = resourceCache.get(resourceId)
      if (resource) {
        updateResource(resource)
        return
      }
    }

    loadingResourceIds.value.push(resourceId)
    try {
      const resource = await resourceService.getResource(resourceId)
      resourceCache.set(resource.id, resource)

      updateResource(resource)
    } finally {
      loadingResourceIds.value = loadingResourceIds.value.filter(
        (r) => r !== resourceId
      )
    }
  }

  function updateResource(resource: Domain.ResourcePlanner.DTO.ResourceData) {
    const resources = currentResources.value.get(
      moment(resource.date).format('YYYY-MM-DD')
    )
    if (!resources) return

    const index = resources.findIndex((r) => r.id === resource.id)
    if (index === -1) return

    resources[index] = resource
  }

  /**
   * Fetches all resources for the selected date range and caches them.
   */
  async function fetchAllResources(force: boolean = false) {
    if (!date.value) return

    await fetchResources(date.value, force)

    const dates = createDatesArray(...dateRange.value)

    for (const d of dates.filter((d) => d !== date.value)) {
      await fetchResources(d, force)
    }

    // Remove resources that are not in the date range
    Array.from(currentResources.value.keys()).forEach((key) => {
      if (!dates.includes(key)) {
        currentResources.value.delete(key)
      }
    })

    calculateSelectedEmployeeIds()
  }

  /**
   * Fetch all resources for the current date and search string.
   */
  async function fetchResources(date: string, force = false) {
    const _date = moment(date)
    const formattedDate = _date.format('YYYY-MM-DD')

    if (
      resourceListCache.has(formattedDate) &&
      !force &&
      !invalidCacheDates.has(formattedDate)
    ) {
      if (!currentResources.value.has(formattedDate)) {
        currentResources.value.set(
          formattedDate,
          resourceListCache.get(formattedDate)!
        )
      }
      return
    }
    invalidCacheDates.delete(formattedDate)

    loadingResources.value = true

    try {
      loadingDates.value.push(formattedDate)
      const { data } = await resourceService.getResources({
        date: date,
        search: searchString.value,
        perPage: -1,
      })
      // If resource is in cache, use the additional details from the cache.
      // We only use the additional details from the cache, because it is not guaranteed that the cache is up to date.
      // The additional details are fetched again, when the resource is opened. This way we can ensure that the details are up to date.
      data.forEach((resource, index) => {
        if (resourceCache.has(resource.id)) {
          data[index].orders = resourceCache.get(resource.id)!.orders
          data[index].rapportdetails = resourceCache.get(
            resource.id
          )!.rapportdetails
        }
      })
      // Remove the resource from the cache, because details should be fetched again when the resource is opened.
      data.forEach((resource) => {
        if (resourceCache.has(resource.id)) {
          resourceCache.delete(resource.id)
        }
      })

      currentResources.value.set(_date.format('YYYY-MM-DD'), data)

      if (
        selectedResource.value &&
        moment(selectedResource.value.date).isSame(date, 'date')
      ) {
        fetchResource(selectedResource.value.id, force)
      }
    } catch (e) {
    } finally {
      loadingDates.value = loadingDates.value.filter((d) => d !== formattedDate)
      loadingResources.value = false
    }
  }

  function getResources(date: Dateable) {
    const _date = moment(date)

    return currentResources.value.get(_date.format('YYYY-MM-DD')) || []
  }

  function getEmployees(date: Dateable) {
    const resources = getResources(date)
    return resources.flatMap((r) => r.planner_entries!).map((r) => r.employee!)
  }

  function getCars(date: Dateable) {
    const resources = getResources(date)
    return resources.flatMap((r) => r.cars!)
  }

  function getTools(date: Dateable) {
    const resources = getResources(date)
    return resources.flatMap((r) => r.tools!)
  }

  const invalidCacheDates = new Set<string>()
  function invalidateCache(date: Dateable, endDate: Dateable | null = null) {
    const dates = createDatesArray(date, endDate)
    for (const date of dates) {
      invalidCacheDates.add(moment(date).format('YYYY-MM-DD'))
    }
  }

  /**
   * Removes a resource from the cache.
   */
  function removeResource(resource: Domain.ResourcePlanner.DTO.ResourceData) {
    const resources = currentResources.value.get(
      moment(resource.date).format('YYYY-MM-DD')
    )
    if (!resources) return

    const index = resources.indexOf(
      resources.find((r) => r.id === resource.id)!
    )
    resources.splice(index, 1)
    currentResources.value.set(
      moment(resource.date).format('YYYY-MM-DD'),
      resources
    )
  }

  async function deleteResource(resource) {
    if (!isEditable(resource)) return

    let resourceExists = null
    try {
      // first check if the resource still exists
      // if not it throws an error and we skip the delete request
      resourceExists = await axios.$get(`resources/${resource.id}`)
    } catch (e) {
      handleError(e)
    }

    if (resourceExists) {
      await resourceService.deleteResource(resource.id)
    }

    removeResource(resource)

    if (
      openResourceCollection.value &&
      openResourceCollection.value.resources.length === 1
    ) {
      // use new instance of resourceCollection
      openResourceCollection.value =
        allResourceCollections.value.find(
          (c) => c.id === openResourceCollection.value?.id
        ) ?? null

      selectedResourceId.value =
        openResourceCollection.value?.resources[0].id ?? null
    } else {
      selectedResourceId.value = null
    }
  }

  async function addEmployeeToSelectedResource(employeeId) {
    if (!selectedResource.value) return

    return addEmployeeToResource(selectedResource.value, employeeId)
  }

  async function confirmOverfill(options: Partial<SweetAlertOptions> = {}) {
    const { value } = await confirmAction({
      confirmButtonText: t('general.yes-add'),
      cancelButtonText: t('general.no'),
      showCancelButton: true,
      icon: 'warning',
      ...options,
    } as SweetAlertOptions)

    return value
  }

  async function addEmployeeToResource(
    resource: Domain.ResourcePlanner.DTO.ResourceData,
    employeeId
  ) {
    if (!isEditable(resource)) return

    const alreadyExists = resource.planner_entries?.find(
      (r) => r.employee?.id === Number(employeeId)
    )
    if (alreadyExists) {
      useDefaultStore().alert({
        type: 'warning',
        text: t(
          'resource-planner.resource.employee-already-exists-at-this-customer'
        ),
      })
      return
    }

    const employee = useEmployeeStore().activeEmployees.find(
      (v) => v.id === Number(employeeId)
    ) as any | undefined

    if ((employee && employee.is_blacklisted) || employee.is_graylisted) {
      const comment = employee.is_blacklisted
        ? employee.blacklist_comment
        : employee.graylist_comment

      const { value } = await confirmAction({
        confirmButtonText: t('resource-planner.employee-darklisted.add-anyway'),
        cancelButtonText: t('resource-planner.employee-darklisted.dont-add'),
        showCancelButton: true,
        icon: 'warning',
        title: employee.is_blacklisted
          ? t('resource-planner.employee-darklisted.employee-blacklisted')
          : t('resource-planner.employee-darklisted.employee-graylisted'),
        text: comment
          ? `${t('resource-planner.employee-darklisted.comment')}: "${comment}"`
          : undefined,
      })

      if (!value) return
    }

    const alreadyUsed = getEmployees(resource.date).some(
      (e) => e.id === Number(employeeId)
    )
    if (alreadyUsed && employee && !employee.resource_planner_white_listed) {
      const add = await confirmOverfill({
        title: t('resource-planner.employee-already-added'),
        text: t('resource-planner.employee-already-added-add-anyway'),
      })

      if (!add) return
    }

    const response = await plannerEntryService.createPlannerEntry(resource.id, {
      employee_id: employeeId,
      added_later:
        alreadyUsed && employee && !employee.resource_planner_white_listed,
    })

    resource.planner_entries?.push(response.plannerEntry)
    resource.rapportdetails?.push(...response.rapportdetails)

    calculateSelectedEmployeeIds()
  }

  async function deletePlannerEntry(plannerEntryId, resource) {
    if (!isEditable(resource)) return

    await plannerEntryService.deletePlannerEntry(plannerEntryId)

    const plannerEntry = selectedResource.value?.planner_entries?.find(
      (r) => r.id === Number(plannerEntryId)
    )
    if (!plannerEntry) return
    const index = selectedResource.value?.planner_entries?.indexOf(plannerEntry)
    if (index === -1 || index === undefined) return
    selectedResource.value?.planner_entries?.splice(index, 1)
    if (selectedResource.value) {
      selectedResource.value.rapportdetails =
        selectedResource.value.rapportdetails?.filter(
          (r) => r.employee_id !== plannerEntry.employee_id
        ) || []
    }

    calculateSelectedEmployeeIds()
  }

  // cars
  function addCarToSelectedResource(carId) {
    if (!selectedResource.value) return

    return addCarToResource(selectedResource.value, carId)
  }

  async function addCarToResource(
    resource: Domain.ResourcePlanner.DTO.ResourceData,
    carId: number | string
  ) {
    if (!isEditable(resource)) return

    const alreadyExists = resource.cars?.find((c) => c.id === Number(carId))
    if (alreadyExists) {
      useDefaultStore().alert({
        type: 'warning',
        text: t('resource-planner.car-could-not-be-added-to-customer'),
      })
      return
    }

    const alreadyUsed = getCars(resource.date).some(
      (c) => c.id === Number(carId)
    )
    if (alreadyUsed) {
      const add = await confirmOverfill({
        title: t('resource-planner.car-alreay-used'),
        text: t('resource-planner.car-alreay-used-for-other-customer'),
      })

      if (!add) return
    }

    // important comment doesnt need to be shown anymore as of: 11.04.2024
    // const car = useCarStore().cars.find((c) => c.id === Number(carId))
    // if (car.important_comment) {
    //   swal(
    //     t('resource-planner.careful-car-important-comment'),
    //     car.important_comment,
    //     'info'
    //   )
    // }

    const data = await resourceService.addCarToResource(
      resource.id,
      Number(carId)
    )
    resource.cars?.push(data)

    if (
      data.is_mobile_home &&
      resource.cars?.filter((c) => c.is_mobile_home).length === 1
    ) {
      // Set all planner entries to sleep in mobile home per default.
      // The database update is done in the backend ad the addCarToResource call.
      resource.planner_entries?.forEach((entry) => {
        entry.sleeps_in_mobile_home = true
      })
    }
  }

  async function removeCarFromSelectedResource(carId) {
    if (!selectedResource.value) return

    removeCarFromResource(selectedResource.value, carId)
  }

  async function removeCarFromResource(resource, carId) {
    if (!isEditable(resource)) return

    await resourceService.removeCarFromResource(resource.id, carId)

    const car = resource.cars.find((c) => c.id === Number(carId))
    const index = resource.cars.indexOf(car)
    resource.cars.splice(index, 1)

    if (car?.is_mobile_home && !resource.cars.some((c) => c.is_mobile_home)) {
      // Planner entries should not be set to sleep in mobile home anymore, if there
      // are no mobile homes at the resource anymore.
      // The database update is done in the backend ad the addCarToResource call.
      resource.planner_entries?.forEach((entry) => {
        entry.sleeps_in_mobile_home = false
      })
    }
  }

  // tools
  async function addToolToSelectedResource(toolId) {
    if (!selectedResource.value) return

    return addToolToResource(selectedResource.value, toolId)
  }

  async function isToolFree(toolId, date: Dateable, amount = 1) {
    const tool = useToolStore().tools.find((t) => t.id === toolId)
    if (!tool) return false

    if ((getAmountOfUsePerTool(date)[toolId] || 0) + amount <= tool.amount)
      return true

    const add = await confirmOverfill({
      title: t('resource-planner.tool-used-up'),
      text: t('resource-planner.tool-used-up-confirm-overfill'),
    })

    return add
  }

  async function addToolToResource(resource, toolId) {
    if (!isEditable(resource)) return

    const alreadyExists = resource.tools.find((t) => t.id === Number(toolId))
    if (alreadyExists) {
      return increaseTool(resource, alreadyExists)
    }

    const free = await isToolFree(Number(toolId), resource.date)
    if (!free) return

    const tool = (await resourceService.addToolToResource(
      resource.id,
      toolId
    )) as Domain.ResourcePlanner.DTO.ToolData

    tool.pivot = {
      amount: 1,
    }
    resource.tools.push(tool)
  }

  async function increaseTool(resource, tool) {
    if (!isEditable(resource)) return

    const free = await isToolFree(tool.id, resource.date)

    if (free) {
      tool.pivot.amount++
      return resourceService.updateToolPivot(resource.id, tool.id, tool.pivot)
    }
  }

  async function decreaseTool(resource, tool) {
    if (!isEditable(resource)) return

    if (tool.pivot.amount === 1) {
      return removeToolFromResource(resource, tool.id)
    } else {
      tool.pivot.amount--
      return resourceService.updateToolPivot(resource.id, tool.id, tool.pivot)
    }
  }

  async function removeToolFromResource(resource, toolId) {
    if (!isEditable(resource)) return

    await resourceService.removeToolFromResource(resource.id, toolId)

    const car = resource.tools.find((c) => c.id === Number(toolId))
    const index = resource.tools.indexOf(car)
    resource.tools.splice(index, 1)
  }

  return {
    // state
    date,
    additionalDays,
    dateRange,
    searchString,
    allResources,
    resourceCollectionsByDay,
    resourcesAtSelectedDay,
    loadingResources,
    loadingDates,
    loadingResourceIds,
    openResourceCollection,
    selectedResourceId,
    getResourcesController,

    // getters
    selectedResource,
    selectedEmployeeIds,
    usedCarIds,
    amountOfUsePerTool,
    availableTools,
    isAllowedToEdit,
    disabled,
    usedCustomers,
    allResourceCollections,

    // actions
    isEditable,
    canAddItemToResource,
    fetchResource,
    fetchResources,
    fetchAllResources,
    getResources,
    invalidateCache,
    deleteResource,
    removeResource,
    addEmployeeToSelectedResource,
    confirmOverfill,
    addEmployeeToResource,
    deletePlannerEntry,
    addCarToSelectedResource,
    addCarToResource,
    removeCarFromSelectedResource,
    removeCarFromResource,
    addToolToSelectedResource,
    isToolFree,
    addToolToResource,
    increaseTool,
    decreaseTool,
    removeToolFromResource,
    clearResourceCache,
  }
})

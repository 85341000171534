import type { RouteRecordRaw } from 'vue-router'

export const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('./pages/LoginPage.vue'),
  },
  {
    path: '/reset-password',
    name: 'auth.reset-password',
    component: () => import('./pages/PasswordReset.vue'),
  },
  {
    path: '/set-password',
    props: (route) => ({
      token: route.query.token,
      email: route.query.email,
    }),
    name: 'auth.set-password',
    component: () => import('./pages/PasswordSet.vue'),
  },

  {
    path: '/roles',
    name: 'roles.index',
    component: () => import('./pages/RolesIndex.vue'),
    meta: { auth: true },
  },
  {
    path: '/roles/:id',
    name: 'roles.edit',
    component: () => import('./pages/RolesEdit.vue'),
    meta: { auth: true },
  },
]

import fileDownload from 'js-file-download'
// import Vue from 'vue'
import { t } from '@/plugins/i18n'
import axios from '@/plugins/axios'
import { COLORS } from '@/constants'
import moment from 'moment'
import { mapOptions } from '@/utils/form-field-options'
import { confirmAction, swal } from '@/utils/sweetalert'

import { useI18n } from 'vue-i18n'

function downloadFile(
  url,
  params: Record<string, any> | null = {},
  inline = false,
  { method = 'get' } = {}
) {
  return new Promise<void>((resolve, reject) => {
    if (inline) {
      const hasParams = url.includes('?')

      const urlParams = new URLSearchParams(params || {}).toString()

      window.open(
        `${window.location.origin}/api/${url}${
          hasParams ? '&' : '?'
        }${urlParams}`,
        '_blank'
      )
      resolve()
      return
    }

    axios[method](
      url,
      method === 'get' ? { params, responseType: 'arraybuffer' } : params,
      method === 'get' ? {} : { responseType: 'arraybuffer' }
    )
      .then((response) => {
        if (!response.headers['content-disposition']) {
          reject()
          return
        }

        const filename = response.headers['content-disposition']
          .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
          .replace(/^"|"$/g, '')

        // const url = window.URL.createObjectURL(new Blob([response.data]))
        // const a = document.createElement('a')
        // a.style.display = 'none'
        // a.href = url
        // a.download = filename
        // document.body.appendChild(a)
        // a.click()
        // window.URL.revokeObjectURL(url)

        fileDownload(response.data, filename)
        resolve()
      })
      .catch((error) => {
        try {
          const responseData = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(error.response.data))
          )
          reject(responseData)
        } catch {
          reject(error)
        }
      })
  })
}

const emailRegex = /^.+@([\w-]+\.)+[\w-]{2,4}$/

const rules = {
  required: (v) => !!v || v === false || v === 0 || t('general.rules.required'),
  nullableEmail: (v) => !v || emailRegex.test(v) || t('general.rules.email'),
  email: (v) => emailRegex.test(v) || t('general.rules.email'),
  integer: (v) => {
    return !v || Number.isInteger(Number(v)) || t('general.rules.integer')
  },
  between: (min, max) => {
    return (v) =>
      !v || (v >= min && v <= max) || t('general.rules.between', [min, max])
  },
  after: (after, { nullableAfter = false } = {}) => {
    return (v) => {
      const afterDate = typeof after === 'function' ? after() : after
      return (
        !v ||
        (nullableAfter && !afterDate) ||
        moment(v, 'YYYY-MM-DD').isSameOrAfter(afterDate, 'day') ||
        t('general.rules.after', [moment(afterDate).format('DD.MM.YYYY')])
      )
    }
  },
  max: (max) => {
    return (v) => {
      return !v || v.length <= max || t('general.rules.max', [max])
    }
  },
  min: (min) => {
    return (v) => {
      return !v || v.length >= min || t('general.rules.min', [min])
    }
  },
}

const employeeFunctions = () => [
  {
    value: null,
    text: t('users.employee-functions.default'),
    rank: 0,
  },
  {
    value: 'driver',
    text: t('users.employee-functions.driver'),
    rank: 1,
  },
  {
    value: 'group-leader',
    text: t('users.employee-functions.group-leader'),
    rank: 2,
  },
  {
    value: 'group-leader-and-driver',
    text: t('users.employee-functions.group-leader-and-driver'),
    rank: 3,
  },
  {
    value: 'representation',
    text: t('users.employee-functions.representation'),
    rank: 4,
  },
]

const contractTypes = [
  {
    value: 'work_contract',
    text: 'Werksvertrag',
  },
  {
    value: 'staff_grant',
    text: 'Personalverleih',
  },
]

const foodTypes = [
  {
    value: 1,
    text: 'Eichhof',
  },
  {
    value: 2,
    text: 'Kunde',
  },
  {
    value: 3,
    text: 'Keine Angabe',
  },
]

const QuarterType = {
  EmployerConfirmation: 0,
  CreditToEichhof: 1,
  FamilyAllowancesPaid: 2,
}

enum UserType {
  Customer = 'customer',
  Worker = 'worker',
  SuperAdmin = 'superadmin',
  Employee = 'employee',
  Guest = 'guest',
}

const WarningType = {
  Verbal: 'verbal',
  Written: 'written',
}

export function useErrorHandling() {
  const { t } = useI18n()

  return {
    handleError(error) {
      if (error.response?.data?.message) {
        swal({
          title: t('general.error'),
          text: error.response.data.message,
          icon: 'error',
          confirmButtonColor: COLORS.PRIMARY,
        })
      } else {
        swal({
          title: t('general.error'),
          text: t('general.unknown-error'),
          icon: 'error',
          confirmButtonColor: COLORS.PRIMARY,
        })
      }
    },
  }
}

export {
  downloadFile,
  rules,
  confirmAction,
  employeeFunctions,
  contractTypes,
  foodTypes,
  QuarterType,
  UserType,
  mapOptions,
  WarningType,
}

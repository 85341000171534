import axios from '@/plugins/axios'
import { ApiService } from '@/api/ApiService'

class ResourceService extends ApiService {
  public getResources(data?: Record<string, any>) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.ResourceData>(
      'getResources',
      ({ cancelToken }) =>
        axios.$post<Domain.ResourcePlanner.DTO.ResourceData[]>(
          `resources/search`,
          data,
          {
            cancelToken,
          }
        ),
      { collection: true, cancellable: true }
    )
  }

  public getResource(id: number) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.ResourceData>(
      `getResource/${id}`,
      ({ cancelToken }) =>
        axios.$get(`resources/${id}`, {
          cancelToken,
        }),
      { collection: false, cancellable: true }
    )
  }

  public updateResource(
    original: Domain.ResourcePlanner.DTO.ResourceData,
    data: Partial<Domain.ResourcePlanner.DTO.ResourceData>
  ) {
    return this.optimisticUpdate(original, data, (id, data) =>
      this.makeRequest<void>(
        'updateResource',
        () => axios.$put(`resources/${id}`, data),
        {
          saving: true,
        }
      )
    )
  }

  public deleteResource(resourceId: number) {
    return this.makeRequest<void>(
      'deleteResource',
      () => axios.$delete(`resources/${resourceId}`),
      {
        saving: true,
      }
    )
  }

  public addCarToResource(resourceId: number, carId: number) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.CarData>(
      'addCarToResource',
      () => axios.$post(`resources/${resourceId}/cars/${carId}`),
      {
        saving: true,
      }
    )
  }

  public removeCarFromResource(resourceId: number, carId: number) {
    return this.makeRequest<void>(
      'removeCarFromResource',
      () => axios.$delete(`resources/${resourceId}/cars/${carId}`),
      {
        saving: true,
      }
    )
  }

  public addToolToResource(resourceId: number, toolId: number) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.ToolData>(
      'addToolToResource',
      () => axios.$post(`resources/${resourceId}/tools/${toolId}`),
      {
        saving: true,
      }
    )
  }

  public removeToolFromResource(resourceId: number, toolId: number) {
    return this.makeRequest<void>(
      'removeToolFromResource',
      () => axios.$delete(`resources/${resourceId}/tools/${toolId}`),
      {
        saving: true,
      }
    )
  }

  public updateToolPivot(
    resourceId: number,
    toolId: number,
    payload: Record<string, any>
  ) {
    return this.makeRequest<void>(
      'updateToolPivot',
      () => axios.$patch(`resources/${resourceId}/tools/${toolId}`, payload),
      {
        saving: true,
      }
    )
  }

  public updateOrderPosition(
    resource: Domain.ResourcePlanner.DTO.ResourceData,
    orders: Domain.OrderManagement.DTO.OrderData[]
  ) {
    return this.optimisticUpdate(resource.orders!, orders, () =>
      this.makeRequest<void>(
        'updateOrderPosition',
        () =>
          axios.$put(`resources/${resource.id}/orders`, {
            orders,
          }),
        {
          saving: true,
        }
      )
    )
  }

  /**
   * If an order has completed resources and no other incomplete resource,
   * the order can be completed on removing from the resource.
   */
  public async checkIfOrdersCanBeCompletedBeforeRemovingFromResource(
    resource: Domain.ResourcePlanner.DTO.ResourceData,
    orderIds: string[]
  ) {
    if (!resource.orders?.length) return false

    const { data: completedResources } = await this.getResources({
      'order_id:in': orderIds,
      completed: true,
    })

    if (completedResources.length === 0) return false

    const { data: incompleteResources } = await this.getResources({
      'order_id:in': orderIds,
      completed: false,
    })

    return incompleteResources.filter((r) => r.id !== resource.id).length === 0
  }

  public getMyResources(date: string) {
    return this.makeRequest<Domain.ResourcePlanner.DTO.ResourceData>(
      'getMyResources',
      () =>
        axios.$get<Domain.ResourcePlanner.DTO.ResourceData[]>(
          `resources/my-resources`,
          {
            params: {
              date,
            },
          }
        ),
      { collection: true }
    )
  }
}

export const resourceService = new ResourceService()
